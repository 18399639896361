import { PublicClientApplication } from '@azure/msal-browser';
import { getClientId, getTenantId } from '../services/environment.service';

export const msalConfig = {
  auth: {
    clientId: getClientId(),
    authority: `https://login.microsoftonline.com/${getTenantId()}`,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  // scopes: ['https://graph.microsoft.com/.default'],
  // scopes: ['https://graph.microsoft.com/User.Read'],
  // scopes: ['api://92a58455-3fd1-4e64-b997-cdadaa63cd1f'],
  // scopes: [`${msalConfig.auth.clientId}/user.read`],
  // scopes: [`api://${msalConfig.auth.clientId}/azuread.read`],
  scopes: [`${msalConfig.auth.clientId}/.default`], // original
};
